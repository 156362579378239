// Styling for the navbar
#mainNav {
  .dropdown-menu{
    background-color: transparent;
  }
  
  .dropdown-item{
    &:hover{
      background-color: transparent;
      filter: drop-shadow(3px 6px 3px black);
    }
  }
}
#Nav{
  .dropdown-menu {
    background-color:  #212629bd;
  }
}

#mainNav, #Nav {
  .navbar{
  background-color: #212529;
  }
  
  .button{
    text-align: end;
  }
  .twitter-location-box{
    display: inline-flex;
  }
  background-color: $gray-900;
  @media(max-width:398px){
    .twitter{
      // position: fixed;
      left: 70px;
      top: 358px;
    }
    
    li{
      margin: 0 10px;
    }
  }
  
  @media(max-width:991px){
    .twitter, .location{
      margin-right: 30px;
    }
    li{
      margin: 0
    }
  }

  .navbar-toggler {
    font-size: 10px;
    right: 0;
    padding: 15px 10px;
    text-transform: uppercase;
    color: white;
    border: 0;
    background-color: $primary;
    @include heading-font;
  }
  .navbar-brand {
    color: #FFF;  // $primary;
    @include heading-font;  //script-font;
    // &.active,
    // &:active,
    // &:focus,
    &:hover {
      filter: drop-shadow(3px 6px 3px black);
    }
  }
  .logo{
    height: 1.8em;
  }
  li{
        width: fit-content;
        line-height: 2.4em;
      }
      .navbar-nav {
    .nav-item {
      .nav-link {
        max-width: fit-content;
        font-size: 90%;
        font-weight: 400;
        letter-spacing: 1px;
        color: white;
        @include heading-font;
        &.active,
        &:hover {
          filter: drop-shadow(3px 6px 3px black);
        }
      }
    }
  
}
  
  
}
@media(min-width:992px) {
  #mainNav {
    .twitter-location-box{
      display: inline-flex;
    }
    padding-top: 25px;
    padding-bottom: 25px;
    -webkit-transition: padding-top 0.3s, padding-bottom 0.3s;
    -moz-transition: padding-top 0.3s, padding-bottom 0.3s;
    transition: padding-top 0.3s, padding-bottom 0.3s;
    border: none;
    background-color: transparent;
    .navbar-brand {
      font-size: 1.45em;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
    }
    .navbar-nav {
      .nav-item {
        .nav-link {
          // margin: 0.8em 1em !important;
        }
      }
    }
    &.navbar-shrink {
      .dropdown-menu {
        background-color:  #212629bd;
    }
      padding-top: 0;
      padding-bottom: 0;
      background-color: $gray-900;
      .navbar-brand {
        font-size: 1.25em;
        padding: 20px 0 10 0;
      }
    }
  }
  li{
    margin: auto;

  }
}
