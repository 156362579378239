// Styling for the timeline section
.timeline {
  position: relative;
  padding: 0;
  list-style: none;
  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 40px;
    width: 2px;
    margin-left: -1.5px;
    height: 1120px;
    content: '';
    background-color: $gray-200;
  }
  > li {
    min-height: 80px;
    &:after,
    &:before {
      display: table;
      content: ' ';
    }
    &:after {
      clear: both;
    }
    .timeline-panel {
      position: relative;
      float: right;
      width: 100%;
      padding: 0 20px 0 100px;
      text-align: left;
      &:before {
        right: auto;
        left: -15px;
        border-right-width: 15px;
        border-left-width: 0;
      }
      &:after {
        right: auto;
        left: -14px;
        border-right-width: 14px;
        border-left-width: 0;
      }
    }
    .timeline-image {
      position: absolute;
      z-index: 100;
      left: 0;
      width: 80px;
      height: 80px;
      margin-left: 0;
      text-align: center;
      color: $white;
      border: 5px solid $gray-200;
      border-radius: 100%;
      background-color: $primary;
      h4 {
        font-size: 10px;
        line-height: 14px;
        margin-top: 5px;
        padding: 10px;
      }
    }
    &.timeline-inverted > .timeline-panel {
      float: right;
      padding: 0 20px 0 100px;
      text-align: left;
      &:before {
        right: auto;
        left: -15px;
        border-right-width: 15px;
        border-left-width: 0;
      }
      &:after {
        right: auto;
        left: -14px;
        border-right-width: 14px;
        border-left-width: 0;
      }
    }
    &:last-child {
      margin-bottom: 70px;
      margin-top: 50px;
    }
  }
  .timeline-heading {
    h4 {
      margin-top: 0;
      color: inherit;
      &.subheading {
        text-transform: none;
      }
    }
  }
  .timeline-body {
     > ul,
    > p {
      margin-bottom: 0;
    }
  }
}


@media(min-width:768px) {
  .timeline {
    &:before {
      left: 50%;
    }
    > li {
      min-height: 100px;
      // margin-bottom: 20px;
      .timeline-panel {
        float: left;
        width: 41%;
        padding: 0 20px 20px 30px;
        text-align: right;
      }
      .timeline-image {
        left: 50%;
        width: 100px;
        height: 100px;
        margin-left: -50px;
        h4 {
          font-size: 12px;
          line-height: 15px;
          padding: 15px;
          margin-top: 8px;
        }
      }
      &.timeline-inverted > .timeline-panel {
        float: right;
        padding: 0 30px 20px 20px;
        text-align: left;
      }
    }
  }

}
@media(min-width:1200px) {
  .timeline {
    > li {
      // min-height: 70px;
      .timeline-panel {
        padding: 0 20px 20px;
        margin-left: -20px;
      }
      .timeline-image {
        width: 150px;
        height: 150px;
        margin-left: -75px;
        h4 {
          padding: 5px;
          font-size: 18px;
          line-height: 26px;
          padding: 30px;
          margin-top: 0px;
        }
      }
      &.timeline-inverted > .timeline-panel {
        padding: 0 20px 20px;
        margin-right: -20px;
      }
    }
  }
}
@media(min-width:768px) {
  .timeline {
    > li {
      min-height: 215px;
      .timeline-panel {
        padding: 0px 30px 50px 0px;
        margin-left: -20px;
      }
      .timeline-image {
        width: 170px;
        height: 170px;
        margin-left: -85px;
        h4 {
        margin-top: 0px;
        padding: 38.9px 30px;
        font-size: 20px;
        line-height: 30px;
        }
      }
      &.timeline-inverted > .timeline-panel {
        padding: 0px 0px 50px 30px;
        margin-right: -20px;
      }
    }
  }

}

@media (max-width: 991px){
  .twitter-box{
      margin: 50px auto;
  }
  .timeline{
    > li {
      min-height: 125px;
    }
    &:before{
      height: 930px;
    }
  }
}
@media ((max-width: 767px)){
  // .twitter-box{
  //     margin: 50px auto;
  // }
  .timeline{
    > li {
      min-height: 125px;
    }
    &:before{
      height: 666px;
    }
  }
}
