// Styling for the masthead
header.masthead {
  text-align: center;
  color: white;
  background-image: url("../img/header-small.png");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  .intro-text {
    padding-top: 150px;
    padding-bottom: 100px;
    .intro-lead-in {
      font-size: 22px;
      line-height: 22px;
      @include serif-font;
    }
    .intro-heading {
      font-size: 50px;
      font-weight: 700;
      line-height: 50px;
      @include heading-font;
    }
  }
}
    .header-logo{
      width: 14em;
    }
@media(min-width: 768px) {
  header.masthead {
  background-image: url("#{$header-image}");
    .intro-text {
      padding-top: 250px;
      .intro-lead-in {
        font-size: 2.1em;
        text-align: left;
        @include heading-font;
      }
      .intro-heading {
        text-align: left;
        font-size: 1.6em;
        font-weight: 500;
        line-height: 75px;
        @include heading-font;
      }
    }
  }
}
.btn.btn-primary{
  margin: 20px auto;
  width: 310px;
}
@media(max-width: 767px) {
  .intro-lead-in, .intro-heading, .header-logo {
    display: none;
  }
}

@media(max-width: 991px) {
  .intro-lead-in {
    font-size: 1.7em;
  p{
    line-height: 1.5;
  }
  }
  p{
    line-height: 1.5;
  }
}
