// Styling for the papers section
#papers {  
  
  .papers-item {
    background: white;
    margin: 20px auto;
    max-width: 290px;
    min-width: 275px;
    height: 450px;
    display: grid;
    border-radius: 3%;
    filter: drop-shadow(0em 0.3em 0.25rem lightgrey);
    .papers-link {
      position: relative;
      display: block;
      margin: 0 auto;
      // height: px;
      cursor: pointer;
      .papers-hover {
        position: absolute;
        width: 100%;
        height: 60%;
        -webkit-transition: all ease 0.5s;
        -moz-transition: all ease 0.5s;
        transition: all ease 0.5s;
        opacity: 0;
        margin-top: 26px;
        background: fade-out($primary, .1);
        &:hover {
          opacity: 1;
          height: 100%;
          width: 100%;
        }
        
        
        .papers-hover-content {
          font-size: 20px;
          position: absolute;
          top: 44%;
          width: 100%;
          height: 20px;
          margin-top: -12px;
          text-align: center;
          color: white;
          i {
            margin-top: -12px;
          }
          h3,
          h4 {
            margin: 0;
          }
        }
      }
      
    }
    .papers-caption {
      max-width: 400px;
      background-color: $white;
      h4 {
        margin: 0;
        text-transform: none;
      }
      p {
        font-size: 14px;
        font-style: italic;
        margin: 0;
        @include serif-font;
      }
    }
  }
  .divider{
  z-index: 3 !important;
  /* background: rebeccapurple; */
  width: 190px;
  padding: 0px;
  border-top: 5px groove #649cd8;
  height: 0px;
  position: absolute;
  bottom: 180px;
  left: 30px;
  }
  .text-muted{
    width: initial;
    font-family: 'Montserrat';
  }
  a.btn {
    margin: 50px auto;
    display: block;
    width: fit-content;
  }

  * {
    z-index: 2;    
  }
  .carousel-item {
    transition: transform 0.5s cubic-bezier(0.4, 0, 1, 1), -webkit-transform 0.5s ease-out;
    backface-visibility: visible;
     
  }
}


.papers-modal {
  padding-left: 10px;
  .modal-dialog {
    margin: 1rem;
    max-width: 100vw;
  }
  .modal-content {
    padding: 100px 0;
    text-align: center;
    h2 {
      font-size: larger;
      text-align: left;
      letter-spacing: 3px;}
    p {
      margin-bottom: 3px;
      text-align: justify;
      font-family: 'Montserrat';
      word-break: break-word;
    }
    p.item-intro {
      font-size: 16px;
      font-style: italic;
      margin: 0px 0 20px;
      @include serif-font;
    }
    ul.list-inline {
      margin-top: 0;
      margin-bottom: 30px;
    }
    img {
      margin-bottom: 30px;
      max-width: 90%;
      border-radius: 3px;
      filter: sepia(0.1);
    }
    button {
      cursor: pointer;
    }
    
  }
  
  .close-modal {
    position: absolute;
    top: 60px;
    right: 50px;
    // width: 75px;
    // height: 75px;
    cursor: pointer;
    background-color: transparent;
    &:hover {
      opacity: 0.3;
    }
    .lr {
      /* Safari and Chrome */
      z-index: 1051;
      width: 1px;
      height: 2.3em;
      margin-left: 35px;
      /* IE 9 */
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      background-color: $gray-900;
      .rl {
        /* Safari and Chrome */
        z-index: 1052;
        width: 1px;
        height: 2.3em;
        /* IE 9 */
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        background-color: $gray-900;
      }
    }
  }
}
.card-img-top{
  margin-top: 45px;
  width: auto;
  height: 12em;
  object-fit: cover;
}

.controls-top{
  float: right;
}

.carousel.slide{
  margin: 0 auto;
  border-radius: 15px;
  filter: drop-shadow(5px 6px 100px lightgrey);
}

.papers-info{
  list-style: none;
  padding: 0;
  li{
    text-align-last: left
  }
  p{
    margin: 0;
  }
}
.card-img{
  min-width: 235px;
}

  hr {
    border: 0;
    border-top: 1px solid #094CFA;
  }

  .card-title {
    padding-top: 0.93em;
    position: absolute;
    font-size: 1em;
    border-top: 5px groove #649cd8;
}

.card-body{
  padding-left: 0.25rem;
  padding-right: 0.35em;
  padding-right: 0.35em;
  width: 12.5em;
}

hr{
  border-top: 1px solid #343b4242;
  margin-top: 0px;
}

@media(max-width:320px){
  #papers{
    .papers-item {
      max-width: 250px;
      min-width: 240px;
      height: 400px;
    }


  .card-img-top {
      height: 10.6em;
      margin-top: 35px;
    }

  // .papers-item .papers-link .papers-hover {
  //   width: 94%;
  // }

  .card-img {
    min-width: 200px;
  }

  .card-body {
    padding-left: 0.15rem;
    padding-right: 1.75em;
    padding-top: 0;
    height: 65px;
  }
}
  
}
.modal-body {
  padding: 4.2em 2.5rem;
  }

@media(max-width:425px){
  .modal-body {
    padding: 4rem 1.4rem;
  }
  .papers-modal .modal-content p.item-intro {
    font-size: 12px;
    font-style: italic;
    margin: 0px 0 5px;
  }
  .btn-primary {
    margin: auto;
    font-size: 11px;
  }
  .lr .rl{
    height: 30px !important;

  }
  .close-modal {
      .lr{
      height: 30px !important;
      right: 5px;
    }
    right: 5px;
    z-index: 1;
  }
}
