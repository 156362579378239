// Styling for the team section
.team-member {
  margin-bottom: 50px;
  text-align: center;
  img {
    width: 225px;
    height: auto;
    border: 7px solid fade-out($black, 0.9);
    padding: 0;
  }
  h4 {
    margin-top: 25px;
    margin-bottom: 0;
    text-transform: none;
  }
  p {
    margin: 10px auto;
    height: 35px;
    line-height: 1.3em;
    font-family: 'Montserrat';
  .role{
    font-weight: 500;
  }
  .topic{
    font-weight: 400;
  }
  }
}

.btn-primary{
  margin: auto;
}
// .fa-orcid{ 
//   &::before{
//       content: " ";
//       background-image: url(../img/icons/orcid.svg);
//       color: white;
//     }
//   }

@media (max-width: 768px){
  
}
@media (max-width: 567px){
  .member-card {
      margin: auto;
  }
}
ul.social-buttons li a{
  padding: 13px;
  line-height: 20px !important;
}

